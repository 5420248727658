import { Container, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
// import MapContainer from "../components/MapBackend";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import MyMapComponent from "../components/Map";
// import { useState, useMemo } from "react";
// import BusinessProfile from "../components/BusinessProfile";
// import GoogleSignIn from "../components/GoogleSignIn";
// import GoogleProfile from "../components/GoogleProfile";

import Spinner from "../components/Spinner";
import ErrorComponent from "../components/ErrorComponent";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Link } from "react-router-dom";
// import PhoneCallButton from "../components/PhoneCallButton";
const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner />;
    case Status.FAILURE:
      return <ErrorComponent />;
    case Status.SUCCESS:
      return <MyMapComponent />;
    default:
      return <MyMapComponent />;
  }
};

const HomeScreen = () => {
  return (
    <div className="homepageMainDiv">
      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 1 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}
      <Card>
        <Card.Body>
          <hr className="sloganTxt"></hr>
          <h1 className="center">
            <span className="sloganTxt">
              One of Grosse Pointe Michigan's Top Roofing Companies
            </span>
            <span className="whiteSmoke">:</span>{" "}
            <span className="highlightRed">Pointe Roofing Improvements</span>
          </h1>
          <hr className="sloganTxt"></hr>

          <Row>
            <Col>
              <p className="pText">
                <span> </span> Family owned, Professional Pointe Roofing with
                Brighter Side Solutions has maintained its reputation for our
                attentive and personable relativeness, remaining aware of all
                our clients roofing and home improvement needs with ideal
                professionalism. Created with you, your home and your happiness
                in mind, we work hard, helping our clients achieve their roofing
                and home improvement projects. You can call us or feel free
                leaving us a message and we will schedule your free estimate at
                your earliest convenience. Our contractors work with residential
                clients and commercial business owners, offering first-class
                roofing services, roof replacement and roof repair. Interested?{" "}
                <a href="tel:+1-313-410-6869">
                  <span className="highlightRed">Get a free estimate </span>
                </a>
                with our roofers in Grosse Pointe, MI.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 2 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      <Card className="content">
        <Card.Body>
          <Card.Title className="sloganTxt">
            Servicing the Grosse Pointe Area
          </Card.Title>
          <h6 className="italian">A reliable contractor you can count on:</h6>
          {/* <Card.Text></Card.Text> */}
          <Row>
            <Col>
              <div className="pText">
                For roof repair, roof replacement, new roof installation, or
                roofing maintenance and inspection solutions, you can count on
                Pointe Roofing Improvements. We offer expert professional
                roofing services for commercial and residential customers in the
                Grosse Pointes and surrounding communities, and our unmatched
                roofing services add both beauty and durability to your
                structure. At Pointe Roofing Improvements, we provide our
                customers with the best quality roofing and building services.
                We have been offering the best customer service since we began
                operating. We perform proven roofing solutions that will
                withstand time and ensure the protection of your family’s most
                valuable asset – your home.
              </div>
              {/* These are the icons from font awesome */}

              <Container className="iconContainer">
                <Row>
                  <Col>
                    <div>
                      <i className="fa-solid fa-house-damage fa-xl"></i>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <i className="fa-solid fa-arrow-alt-circle-right fa-xl"></i>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <i className="fa-solid fa-home major fa-xl "></i>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 3 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      <Card className="content">
        <Card.Body>
          <Card.Title className="sloganTxt">Services</Card.Title>
          <h6 className="italian">All general exterior home improvements:</h6>

          <Container className="servicesLinks">
            <Row className="row2">
              <Col>
                <Nav className="ms-auto">
                  <LinkContainer to="/services">
                    <Nav.Link>Full Replacement</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Gutter Replacement</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Residential Roofing</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Commercial Roofing</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>EPDM Rubberized Roofing</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Torch Down Flat Roofing</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Shingle Roof</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Roof Repair</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link> Gutter Replacement</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Gutter Repair</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <Nav.Link>Gutter Guards</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Col>
            </Row>
          </Container>

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 4 with MAP /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      <Card className="content">
        <Card.Body className="Map">
          <Card.Title className="sloganTxt"> Service Area</Card.Title>
          {/* <Card.Text></Card.Text> */}
          <Row>
            <Col className="actualMap">
              {/* <MapContainer /> */}
              {/* <MyMapComponent /> */}

              <Wrapper
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                render={render}
              >
                <MyMapComponent />
              </Wrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <BusinessProfile />
              <GoogleProfile />
              <GoogleSignIn /> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card.Title>
        {" "}
        <Button href="tel:+1-313-410-6869" variant="danger" className="center">
          <i className="fa-solid fa-phone"> </i> (313)410-6869
        </Button>
        <Link></Link>
      </Card.Title>

      {/* <PhoneCallButton
        onClick={() => {
          window.location.href = "tel:313-410-6869";
        }}
      /> */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default HomeScreen;
