import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import HomeScreen from "./screens/Homescreen";
import AboutScreen from "./screens/Aboutscreen";
import ReviewsScreen from "./screens/Reviewsscreen";
import ServicesScreen from "./screens/Servicesscreen";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<HomeScreen />}>
      <Route
        exact
        index={true}
        path="/"
        element={<HomeScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        exact
        index={true}
        path="#"
        element={<HomeScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        exact
        index={true}
        path="/#/"
        element={<HomeScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        index={true}
        path="/home"
        element={<HomeScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        index={true}
        path="/about"
        element={<AboutScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        index={true}
        path="/services"
        element={<ServicesScreen />}
        errorElement={<HomeScreen />}
      />
      <Route
        index={true}
        path="/reviews"
        element={<ReviewsScreen />}
        errorElement={<HomeScreen />}
      />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
