import { Row } from "react-bootstrap";
import { Col, Card } from "react-bootstrap";
import lauren from "../assets/images/reviewspage/lauren.jpeg";
import james from "../assets/images/reviewspage/james.jpeg";
import dan from "../assets/images/reviewspage/dan.jpeg";
import reno from "../assets/images/reviewspage/RenoReview.jpeg";

const ReviewsScreen = () => {
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <hr className="sloganTxt"></hr>
              <h1 className="sloganTxt">Reviews </h1>
              <hr className="sloganTxt"></hr>
              <Card.Title>Lauren</Card.Title>
              <Card.Img src={lauren}></Card.Img>
              {/* <Card.Text> */}
              <Row>
                <Col>
                  <div className="pText">
                    Jonah was installing a roof for my neighbor and stopped by
                    my house when he noticed pieces of my roof that were in my
                    front yard. He offered me a free estimate. Jonah was very
                    professional and efficient. He texted me a quote the next
                    day (including pictures of the damage) and shortly after
                    that sent over a contract by email. His quote was
                    reasonable, and he was able to do the work within the week,
                    which was awesome due to the nature of the roof repair.
                    Jonah showed up on time, worked all day, and returned the
                    next day to finish a few details he felt was important. I
                    loved that he sent me before and after photos because it’s
                    hard to tell with it being a roof. We have already planned
                    to have Jonah fix a window and our deck in the spring. Def
                    recommend him!!
                  </div>
                </Col>
              </Row>
              {/* </Card.Text> */}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>James</Card.Title>
              <Card.Img src={james}></Card.Img>
              {/* <Card.Text> */}
              <Row>
                <Col>
                  <div className="pText">
                    Jonah and his crew were truly professional, attentive, hard
                    working and thorough. They installed a whole new gutter
                    system around my home in Grosse Pointe, and did a great job.
                    Their prices were also very competitive and fair.
                  </div>
                </Col>
              </Row>
              {/* </Card.Text> */}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Reno</Card.Title>
              <Card.Img src={reno}></Card.Img>
              {/* <Card.Text> */}
              <Row>
                <Col>
                  <div className="pText">
                    The owner was fast and professional and took pictures and
                    updated me on the progress of my my roof replacement while I
                    was at work. You can’t ask for anything more!
                  </div>
                </Col>
              </Row>
              {/* </Card.Text> */}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Dan</Card.Title>
              <Card.Img src={dan}></Card.Img>
              {/* <Card.Text> */}
              <Row>
                <Col>
                  <div className="pText">
                    Jonah and his team installed a new roof on my mother's ranch
                    house in Grosse Pointe. The work was completed timely and
                    with great precision. The clean up was completed immediately
                    after the roof was installed. Nothing was left behind. The
                    entire team was polite and professional. The young men who
                    did the work were very fit and seemed well suited to do the
                    work. On top of all of the good comments I can make about
                    the work itself, the price was also very reasonable. I would
                    highly recommend Pointe Roofing.
                  </div>
                </Col>
              </Row>
              {/* </Card.Text> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default ReviewsScreen;
