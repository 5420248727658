import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import image from "../assets/images/aboutus/jonah.jpeg";

const Aboutscreen = () => {
  return (
    <div className="homepageMainDiv">
      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 1 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      <Card>
        <Card.Body className="">
          <hr className="sloganTxt"></hr>
          <h1 className="sloganTxt">
            The Owner of Pointe Roofing Improvements
          </h1>
          <hr className="sloganTxt"></hr>

          <Card.Text></Card.Text>
          <Row>
            <Col>
              <Container fluid>
                <Row>
                  <Col>
                    <Card.Img
                      id="jImg"
                      src={image}
                      width={"100%"}
                      height={"100%"}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Meet Jonah</Card.Title>

          <Card.Text></Card.Text>
          <Row>
            <Col>
              <div className="pText">
                Jonah-Dane has been working in the home improvement industry
                since he was in high school. Learning most of his skills working
                with his family, Jonah eventually decided to venture off and
                start a roofing business of his own. Jonah is a father of 1, and
                a friend to many. Spending time with his family, playing his
                guitar, exercising, traveling and most importantly his health
                and wellness are just a few things Jonah is big on. Having a
                passion for the performing arts, graduating from an art school,
                Jonah always had an eye for esthetics, style and art.
              </div>
              <br />
              <div className="pText">
                Using his talents, Jonah has built a strong relationship between
                business and his many happy customers including a long lasting
                name for himself that displays what hard work can accomplish.
                Jonah-Dane is just one phone call or text away if you would like
                to receive an estimate and contract from Pointe Roofing
                Improvements, you can get started today. Jonah will be there to
                help you and will walk you through your project, every step of
                the way.
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Button href="tel:+1-313-410-6869" variant="danger" className="center">
          <i className="fa-solid fa-phone"> </i> (313)410-6869
        </Button>
      </Card>

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 2 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 3 /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      {/* ///////////////////////////////////////////////////////////////// */}
      {/* //////////////////////// Section 4 with MAP /////////////////////////////// */}
      {/* ///////////////////////////////////////////////////////////////// */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Aboutscreen;
