import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import fullReplacement from "../assets/images/servicespage/fullReplacement.jpeg";
import commercialRoofing from "../assets/images/servicespage/commercialRoofing.jpeg";
import residentialRoofing from "../assets/images/servicespage/residentialRoofing.jpeg";
import epdmRoofing from "../assets/images/servicespage/epdmRubberizedRoofing.jpeg";
import torchDownRoofing from "../assets/images/servicespage/torchDownFlatRoofing.jpeg";
import generalRoofingInfo from "../assets/images/servicespage/generalRoofingInformation.png";
// import fullReplacement from "../assets/images/servicespage/fullReplacement.jpeg";
// import fullReplacement from "../assets/images/servicespage/fullReplacement.jpeg";

const ServicesScreen = () => {
  return (
    <div>
      <Row>
        <Col>
          <div className="homepageMainDiv">
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 1 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}

            <Card>
              <Card.Body>
                <hr className="sloganTxt"></hr>
                <h1 className="sloganTxt">Services</h1>
                <hr className="sloganTxt"></hr>
                <Card.Title>Full Replacements</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={fullReplacement}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      You decide that it’s time to have your Commercial or
                      Residential Roof replaced, this is where we come in. From
                      setting up, to the tearing out and installing, call us and
                      we’ll take care of the process for you.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 2 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>Residential Roofing</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={residentialRoofing}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      If you’re looking to repair a simple leak or have your
                      roof replaced, we can cover all your commercial roofing
                      needs. Residential roofing reference to any small or large
                      family home, any style or size.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>Commercial Roofing</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={commercialRoofing}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      If you’re looking to repair a simple leak or have your
                      roof replaced, we can cover all your commercial roofing
                      needs. Commercial roofing refers to the installation of
                      commercial roofs for businesses, warehouses, and other
                      large commercial structures.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>EPDM Rubberized Roofing</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={epdmRoofing}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      EPDM, is a synthetic rubber that is extremely durable and
                      used across a variety of applications. EPDM is heat and
                      weather resistant with its two main ingredients derived
                      from natural gas and oil, making it an ideal flat roofing
                      material.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>Torch Down Flat Roofing</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={torchDownRoofing}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      Torch down roofing (sometimes referred to as “torch on”
                      roofing) is so named because it requires an open-flame
                      propane torch. In this installation method, sheets of
                      modified bitumen are rolled out onto the roof, and a
                      roofing professional uses a hand-held propane torch to
                      heat the material and adhere it to the surface.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>General Roofing Information</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={fullReplacement}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      You decide that it’s time to have your Commercial or
                      Residential Roof replaced, this is where we come in. From
                      setting up, to the tearing out and installing, call us and
                      we’ll take care of the process for you.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}
            <Card>
              <Card.Body>
                <Card.Title>General Roofing Information</Card.Title>
                <Card.Text></Card.Text>
                <Card.Img src={generalRoofingInfo}></Card.Img>
                <Row>
                  <Col>
                    <div className="pText">
                      General Roofing Information: Not everyone knows what a
                      roof system consist of, what makes a roof work and the
                      different parts. Let’s take a quick moment and get
                      familiar with one of the most important parts of your
                      home. We cover every kind of Roof Repair: We receive calls
                      daily from clients who need there roofs repaired, wether
                      it is a shingle repair, torch down repair, EPDM Repair,
                      etc we specialize in meeting your needs. If you are
                      looking for any one of these services listed below, your
                      next step is following up with us and setting up an
                      estimate now.
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* ///////////////////////////////////////////////////////////////// */}
            {/* //////////////////////// Section 3 /////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////// */}

            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicesScreen;
