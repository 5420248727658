import "./App.css";
import "./index.css";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Header />
      {/* <main className="py3"> */}
      <Container>
        <Outlet />
      </Container>

      {/* </main> */}
      <Footer />
    </div>
  );
}

export default App;
